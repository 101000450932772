import { useState, useEffect } from "react";
import urlSet from '../../utils/urls';
import axios from 'axios';
import AssociatesCard from "../cards/AssociatesCard";
import useWindowSize from "../useWindowSize";

const CoachesSection = () => {
    const [associates, setAssociates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [width] = useWindowSize();

    useEffect(() => {
        const getAssociates = async () => {
            try {
                let url = urlSet.viewEmployeesApi.url;
                setLoading(true);
                const res = await axios.get(encodeURI(url));
                let data2 = [];
                for(let i = 0; i < res.data.length; i++){
                  if(res.data[i].role == 'coach'){
                    data2.push(res.data[i]);
                  }
                }
                setAssociates(data2);
                setLoading(false);
            } catch (err) {
                setLoading(false);
            }
        };
        getAssociates();
    }, []);
    
    return(
        <div>
            <div className="coachesHeading mb-5">
                <div className="coachesHeading1">
                    <div className="bco fw-700 fo-50">MEET OUR COACHES</div>
                </div>
                <div className="coachesHeading2">
                    <div></div>
                </div>
            </div>

            {
                !loading && <EmployeeGrid associates={associates} eachRow={width > 768 ? 4 : 2}/>
            }
        </div>
    )
}

export default CoachesSection;

const EmployeeGrid = ({ associates, eachRow=4 }) => {
    const [ rows, setRows ] = useState([]);
  
    useEffect(() => {
      let temp = [];
      const rowCount = Math.ceil(associates.length / eachRow);
      for(let i = 0; i < rowCount; i++){
        temp.push(associates.slice(eachRow*i, (eachRow*i)+eachRow));
      }
      setRows(temp);
    }, [eachRow, associates]) 
    return(
      <div>
        { rows.map(( row, index ) => <EmployeeRow associates={row} key={index} />) }
      </div>
    )
  }
  
  const EmployeeRow = ({ associates }) => {
      return (
          <div className="coachesSetion d-block">
              <div className="coachList">
                  <div className="row bg-white m-0 mb-5 justify-content-center">
                      {associates.map((associate, index) => (
                          <AssociatesCard associate={associate} key={index} />
                      ))}
                  </div>
              </div>
          </div>
      );
  };  
