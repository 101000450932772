import Link from "next/link";

const HeaderSection = () => {
  return (
    <section>
      <div className="position-relative text-center">
        <img src="/assets/landing_cover.webp" className="w-100 d-none d-sm-block" alt="Landing cover"></img>
        <img src="/assets/landing_cover_mobile.webp" className="w-100 d-sm-none" alt="Landing cover"></img>
        <div className="absolute_button m-auto">
          <div className="text-center">
            <div className="sendButton consultationButton pulsating">
              <Link href='/chooseProgram'>Start Your Journey!</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 mt-sm-5">
        <div className="fo-50 bco fw-700 text-center mfo-32">
          Our Methodology{" "}
        </div>
        <div className="mt-2 fo-26 txtco fw-700 text-center mfo-18">
          THE 3 POINT APPROACH
        </div>

        <div className="ps-lg-5 pe-lg-5">
          <div className="ps-lg-5 pe-lg-5">
            <div className="ps-lg-5 pe-lg-5">
              <div className="ps-lg-5 pe-lg-5">
                <div className="ps-3 pe-3">
                  <div className="fo-18 justify mt-4 mfo-14">
                  Our team at Optimum Wellness believes that a holistic approach is the best way to achieve overall physical, nutritional, and mental well-being. Using a three-point approach: nutrition, exercise, and rest, Optimum Wellness offers personalized, effective solutions for every individual. Rooted in science and with over a decade of industry experience, we have unparalleled knowledge of what it takes to help you achieve your goals optimally. 
                  </div>

                  <div className="row m-0 mt-5">
                    <div className="col-sm-4 mb-4 text-center">
                      <img src="/assets/methodology1.png" className="w-80 m-auto" alt="methodology1"></img>
                      <div className="mt-3 fo-20 txtco fw-700 text-center mfo-18">
                        Quantified Nutrition
                      </div>
                      <div className="mt-4 fo-18 text-center mfo-16">
                      Experience the transformative power of scientific nutrition combined with sustainable food choices.
                      </div>
                    </div>
                    <div className="col-sm-4 mb-4 text-center">
                      <img src="/assets/methodology2.png" className="w-80 m-auto"  alt="methodology2"></img>
                      <div className="mt-3 fo-20 txtco fw-700 text-center mfo-18">
                        Result-Oriented Exercise
                      </div>
                      <div className="mt-4 fo-18 text-center mfo-16">
                      From simple daily activities to intense workouts, our exercise guidance helps you feel better and live healthier. 
                      </div>
                    </div>
                    <div className="col-sm-4 mb-4 text-center">
                      <img src="/assets/methodology3.png" className="w-80 m-auto"  alt="methodology3"></img>
                      <div className="mt-3 fo-20 txtco fw-700 text-center mfo-18">
                        Appropriate Rest
                      </div>
                      <div className="mt-4 fo-18 text-center mfo-16">
                      We empower you to utilise the fundamental impact of rest and recovery for your well-being.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center">
        <div className="sendButton consultationButton pulsating">
          <Link href='/chooseProgram'>Get a Free Consultation</Link>
        </div>
      </div>
    </section>
  );
};

export default HeaderSection;
