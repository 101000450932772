import { useState, createRef } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import Link from "next/link";

const data = {
  1: {
    title: "Step 1: Select your Programme",
    image: "/assets/works1.png",
    text: "Fill up a preliminary assessment form and provide us with relevant information about your lifestyle that enables our experts to create the right customized programme for your goals.",
  },
  2: {
    title: "Step 2: Consult Your Coach",
    image: "/assets/works2.png",
    text: "Your designated coach shall have a word with you and discuss your goals in depth and discuss how our programmes shall help you achieve the same.",
  },
  3: {
    title: "Step 3: Get Your Customized Diet And Exercise Plan",
    image: "/assets/works3.png",
    text: "We shall send you your customized nutrition and exercise plan along with recipes and supplementation guidance if required. This plan is subject to further revision.",
  },
  4: {
    title: "Step 4: Receive regular follow ups and motivation",
    image: "/assets/works4.png",
    text: "Your dedicated coach along with a team of wellness guides shall motivate and follow-up with you on a weekly basis.",
  },
  5: {
    title: "Step 5: Celebrate your achievements ",
    image: "/assets/works5.png",
    text: "Start witnessing results as soon as you start following the plans and rejoice every minor and major milestone you conquer.",
  },
};

const WorkflowSection = () => {
  const splide = createRef();
  const [step, setStep] = useState(1);

  return (
    <div>
      <div className="workflowSetion">
        <div className="fo-34 txtco fw-700 text-center mfo-28">
          How It Works
        </div>

        <div className="workflowToggleSection mt-4">
          {Object.keys(data).map((s) => {
            return (
              <div
                key={s}
                className={`workflowToggleDiv cursorPointer ${step == s && "active"}`}
                onClick={() => {
                  setStep(s);
                  splide.current.splide.go(s - 1);
                }}
              >
                <div className="fo-20 fw-700">{s}</div>
              </div>
            );
          })}
        </div>
        <Splide
          aria-label="My Favorite Images"
          ref={splide}
          options={{
            arrows: false,
            pagination: false,
            drag: false,
            start: 0,
          }}
        >
          {Object.keys(data).map((s) => {
            return (
              <SplideSlide key={s}>
                <div className="text-center mt-4 workflowSteps p-3 p-sm-0">
                  <div className="bco fo-20 fw-700">{data[s].title}</div>
                  <img src={data[s].image} className="w-70 mt-3" alt={data[s].title} />
                  <div className="fo-16 mt-4">{data[s].text}</div>
                </div>
              </SplideSlide>
            );
          })}
        </Splide>
      </div>

      <div className="text-center">
        <div className="sendButton consultationButton pulsating">
          <Link href='/chooseProgram'>Talk to an Expert!</Link>
        </div>
      </div>
    </div>
  );
};

export default WorkflowSection;
