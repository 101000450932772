const NewsSection = () => {
  return (
    <div>
      <div className="row m-0">
        <div className="col-6 col-sm-6 col-md-4 m-auto text-center">
          <a href="https://www.facebook.com/nfna.education/photos/a.370415893855728/949739359256709/?paipv=0&eav=AfbfklpZ1nBVKqSK42nl2XP9Uto_NR9LjnETFuOprkYLg07dtKf-mGaoWFzNMP7Nyog&_rdr">
            <img
              src="/assets/news/Indian_Express.png"
              alt="Indian Express"
              className="w-70 mt-5 mow-100"
            ></img>
          </a>
        </div>
        <div className="col-6 col-sm-6 col-md-4 m-auto text-center">
          <a href="https://timesofindia.indiatimes.com/life-style/health-fitness/weight-loss/weight-loss-i-was-taunted-by-my-close-friend-for-having-man-boobs/articleshow/67953957.cms">
            <img
              src="/assets/news/The_Times_Of_India.png"
              alt="The Times Of India"
              className="w-70 mt-5 mow-100"
            ></img>
          </a>
        </div>
        <div className="col-6 col-sm-6 col-md-4 m-auto text-center">
          <a href="https://www.gqindia.com/content/how-to-lose-weight-like-this-23-year-old-nutritionist-who-lost-35-kg-in-just-9-months-weight-loss-inspirations">
            <img
              src="/assets/news/GQ.png"
              alt="GQ"
              className="w-70 mt-5 mow-100"
            ></img>
          </a>
        </div>
        <div className="col-6 col-sm-6 col-md-4 m-auto text-center">
          <a href="https://m.facebook.com/story.php?story_fbid=4354644617962440&id=100002508336418">
            <img
              src="/assets/news/Financial_Express.png"
              alt="Financial Express"
              className="w-70 mt-5 mow-100"
            ></img>
          </a>
        </div>
        <div className="col-6 col-sm-6 col-md-4 m-auto text-center">
          <a href="https://www.rediff.com/getahead/report/fat-to-fit-how-i-lost-30-kg-in-less-than-a-year/20211227.htm">
            <img
              src="/assets/news/rediff.png"
              className="w-70 mt-5 mow-100"
              alt="Rediff image"
            ></img>
          </a>
        </div>
        <div className="col-6 col-sm-6 col-md-4 m-auto text-center">
          <a href="https://www.mensxp.com/health/motivation/49170-31-days-of-fitness-alan-lost-30-kgs-got-down-to-9-body-fat-doing-things-the-right-way.html">
            <img
              src="/assets/news/mensxp.png"
              className="w-70 mt-5 mow-100"
              alt="MensXP image"
            ></img>
          </a>
        </div>
      </div>
    </div>
  );
};

export default NewsSection;
