import Head from "next/head";
import HeaderSection from "../components/sections/HeaderSection";
import CoachesSection from "../components/sections/CoachesSection";
import WorkflowSection from "../components/sections/WorkflowSection";
import SuccessStorySection from "../components/sections/SuccessStorySection";
import NewsSection from "../components/sections/NewsSection";

import Link from "next/link";
export default function Home() {
  return (
    <div>
      <Head>
        <meta property="og:image" content={"https://assets.alanvidyushbaptist.com/optimumwellness_logo.jpg"} />
        <title>Optimum Wellness</title>
      </Head>
      <HeaderSection />
      <WorkflowSection />
      <SuccessStorySection />
      <div className="text-center">
        <div className="sendButton consultationButton pulsating">
          <Link href='/chooseProgram'>Get a Free Consultation</Link>
        </div>
      </div>
      
      <CoachesSection />

      <div className="mt-5 fo-34 txtco fw-700 text-center mfo-24 d-flex m-auto justify-content-center">
        Join Us On
        <a href="https://www.facebook.com/groups/2020362948135208">
        <div className="tablet pulsating">Facebook</div>
        </a>
      </div>
      <img src="/assets/home.png" className="w-100 mt-5 mb-5" alt="home" />
      <div className="mt-2 fo-34 txtco fw-700 text-center mfo-28">
        In The News
      </div>
      <div className="ps-lg-5 pe-lg-5">
        <div className="ps-3 pe-3 ps-lg-5 pe-lg-5">
          <NewsSection/>
        </div>
      </div>
      <div className="text-center mt-5 mb-5">
        <div className="sendButton consultationButton pulsating">
          <Link href='/chooseProgram'>Talk to an Expert!</Link>
        </div>
      </div>
    </div>
  );
}
