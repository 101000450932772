import { Splide, SplideSlide } from "@splidejs/react-splide";
import stories from "../../utils/success_stories.json";

const SuccessStorySection = () => {
  return (
    <div>
      <div className="successStoryCard">
        <Splide
          aria-label="My Favorite Images"
          options={{
            arrows: false,
            pagination: false,
            type: "loop",
            autoplay: true,
            pauseOnHover: false,
            resetProgress: false,
          }}
        >
          {stories.map((story, index) => {
            return (
              <SplideSlide key={index}>
                <div className="row m-0 font-gelasio">
                  <div className="col-md-4 m-auto">
                    <img src={story.client_image} className="w-100" alt="client" loading="lazy"/>
                  </div>
                  <div className="col-md-8 m-auto">
                    <div className="ms-sm-4">
                      <div className="fo-50 text-white fw-600 hr mfo-32">
                        SUCCESS STORIES
                      </div>
                      <div className="fo-26 text-white fw-600 mt-4">
                        {story.client_name}
                      </div>
                      <div className="ps-4 pe-4 ps-lg-5 pe-lg-5 position-relative">
                        <img src='/assets/quote1.png' className="quote1" alt="quote"/>
                        <img src='/assets/quote2.png' className="quote2" alt="quote"/>
                        <div className="ps-2 pe-2 ps-sm-4 pe-sm-4 ps-md-4 pe-md-4 ps-xl-5 pe-xl-5">
                          <div className="fo-14 text-white mt-4 font-gelasio">
                            {story.client_review}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SplideSlide>
            );
          })}
        </Splide>
      </div>
    </div>
  );
};

export default SuccessStorySection;
