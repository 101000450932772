const AssociatesCard = ({ associate }) => {
  return (
    <div className="associates_grid ps-sm-4 pe-sm-4">
      <div className="coachDivImage">
          <img src={associate.image} className="w-100" alt='Coach'></img>
      </div>
      <div className="fo-20 mfo-14 fw-700 bco mt-4 dyna text-center">{associate.name}</div>
      <div className="fo-13 mt-2 dyna text-center">{associate.description}</div>
  </div>
  );
};

export default AssociatesCard;
